import { Point } from "geojson";
import { Identifier } from "react-admin";

import { DeviceType } from "./common";
import { ColumnsOf } from "../data";
import { Maybe } from "../utility";

export const deviceState = {
  Securing: "securing",
  Found: "secured",
  Lost: "lost"
} as const;

export type DeviceStates = Array<(typeof deviceState)[keyof typeof deviceState]>;

export interface DeviceBase<TType extends string = DeviceType> {
  id: string;
  name: string;
  type: TType;
  tags?: Maybe<string[]>;

  tid?: Maybe<string>;
  gwid?: Maybe<string>;
  gw_name?: Maybe<string>;
  state?: Maybe<DeviceStates>;

  person?: Maybe<string>;

  created_by?: Maybe<string>;
  created_at: string;

  updated_by?: Maybe<string>;
  updated_at: string;

  is_present: boolean;

  present_on?: Maybe<string[]>;
  present_at?: Maybe<string>;

  location?: Maybe<Point>;
  located_at?: Maybe<string>;
  address?: Maybe<string>;

  space_id: Identifier;
  space_name: string;

  site_id?: Maybe<Identifier>;
  site_name?: Maybe<string>;
  site_position?: Maybe<Point>;

  zone_id?: Maybe<Identifier>;
  zone_name?: Maybe<string>;

  wifi_bssid?: Maybe<string>;
  wifi_ssid?: Maybe<string>;

  meta?: Maybe<{
    secured: number;
    k?: Maybe<number>;
    deleted?: Maybe<boolean>;
    lowBatt?: Maybe<boolean>;
    firmware?: Maybe<string>;
    manufacturer?: Maybe<string>;
    modelNum?: Maybe<string>;
  }>;
}

export const DEVICE_BASE_COLUMNS: ColumnsOf<DeviceBase> = ["*"] as const;

import { GridFilterModel } from "@mui/x-data-grid-pro";

import {
  isOperatorValid,
  isQuickFilterValid,
  isValueValid,
  MathOperator,
  mathOperator,
  Maybe,
  operator,
  Operator,
  Query,
  ResourceType,
  Space
} from "types";

import { FOREIGN_KEY_SEPARATOR } from "../applySortingToQuery";
import { hasFullTextSearchIndex } from "../hasFullTextSearchIndex";

const mapSearchIndexForResource = (res: ResourceType) => {
  return hasFullTextSearchIndex(res) ? `search` : "name";
};

export const operatorQueryMap: Record<
  Operator | MathOperator,
  (query: Query<unknown>, field: string, value: never) => Query<unknown>
> = {
  [operator.equals]: (query, field, value) => query.eq(field, value),
  [operator.notEquals]: (query, field, value) => query.neq(field, value),
  [operator.is]: (query, field, value) => query.is(field, value as never),
  [operator.isNot]: (query, field, value) => query.not(field, "is", value),

  [operator.contains]: (query, field, value) => query.ilike(field, `%${value}%`),
  [operator.endsWith]: (query, field, value) => query.ilike(field, `%${value}`),
  [operator.startsWith]: (query, field, value) => query.ilike(field, `${value}%`),

  [operator.isAnyOf]: (query, field, value) => query.in(field, value as never),
  [operator.isEmpty]: (query, field) => {
    if (field.includes(FOREIGN_KEY_SEPARATOR)) {
      const [table, column] = field.split(FOREIGN_KEY_SEPARATOR);
      return query.or(`${column}.is.null, ${column}.eq.""`, { referencedTable: table });
    }

    return query.or(`${field}.is.null, ${field}.eq.""`);
  },
  [operator.isNotEmpty]: (query, field) => query.not(field, "is", null).neq(field, ""),

  [operator.after]: (query, field, value) => query.gt(field, value),
  [operator.greaterThan]: (query, field, value) => query.gt(field, value),

  [operator.onOrAfter]: (query, field, value) => query.gte(field, value),
  [operator.greaterThanOrEqual]: (query, field, value) => query.gte(field, value),

  [operator.before]: (query, field, value) => query.lt(field, value),
  [operator.lessThan]: (query, field, value) => query.lt(field, value),

  [operator.onOrBefore]: (query, field, value) => query.lte(field, value),
  [operator.lessThanOrEqual]: (query, field, value) => query.lte(field, value),

  [operator.isNull]: (query, field) => query.is(field, null as never),
  [operator.isNonNull]: (query, field) => query.not(field, "is", null as never),
  [operator.isFalse]: (query, field) => query.eq(field, false),
  [operator.isTrue]: (query, field) => query.eq(field, true),
  [operator.matches]: (query, field, value) => query.filter(field, "match", value),
  [operator.notMatches]: (query, field, value) => query.not(field, "match", value),

  // Array operators
  [operator.arrayContains]: (query, field, value) => query.contains(field, value),
  [operator.arrayIsEmpty]: (query, field) => query.or(`${field}.is.null, ${field}.eq.{}`),

  // Math operators
  [mathOperator.equals]: (query, field, value) => query.eq(field, value),
  [mathOperator.notEquals]: (query, field, value) => query.neq(field, value),
  [mathOperator.greaterThan]: (query, field, value) => query.gt(field, value),
  [mathOperator.greaterThanOrEqual]: (query, field, value) => query.gte(field, value),
  [mathOperator.lessThan]: (query, field, value) => query.lt(field, value),
  [mathOperator.lessThanOrEqual]: (query, field, value) => query.lte(field, value)
};

export const applyFiltersToQuery = <TData>(
  res: ResourceType,
  query: Query<TData>,
  filters: Maybe<GridFilterModel>,
  spaces: Maybe<Space[]>
) => {
  if (spaces?.length) {
    query = query.in(
      "space_id",
      spaces.map((s) => s.id)
    );
  }

  if (!filters) {
    return query;
  }

  if (isQuickFilterValid(filters.quickFilterValues)) {
    query = query.textSearch(
      mapSearchIndexForResource(res),
      `${filters.quickFilterValues.join("_").toLowerCase()}:*`
    );
  }

  if (filters.items?.length > 0) {
    filters.items.forEach(({ value, operator, field }) => {
      if (isOperatorValid(operator) && isValueValid(value, operator)) {
        operatorQueryMap[operator](query, field, value as never);
      }
    });
  }

  return query;
};
